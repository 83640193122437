<template>
    <modal ref="modalCambiarTodosCedis" titulo="Cambiar valor en todos los Cedis de Forma Masiva" icon="money" :cargando="loading" @guardar="aceptar">
        <div v-for="item in distinMonedas" :key="item.id" class="row mx-0 justify-center">
            <monedasCambiar :item="item" />
        </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
import CedisProductos from '~/services/cedis/cedis_productos'


export default {
    components: {
        monedasCambiar: () => import('~/pages/productos/admin/components/monedasCambiar'),
    },
    data(){
        return{
            loading: false,
            distinMonedas: []
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
            monedas:'cedis/monedas',
            cedis:'cedis/cedis',
        }),
    },
    methods: {
        toggle(){
            this.getDistinMonedas()
            this.$refs.modalCambiarTodosCedis.toggle()
        },
        getDistinMonedas(){
            let ids_monedas = this.cedis.map( e=> e.idm_moneda)
            ids_monedas = ids_monedas.filter(e => e && e!==0 )
            const uniqueMoendas = ids_monedas.filter((x, i, a) => a.indexOf(x) == i)
            this.distinMonedas= this.monedas.filter(e => uniqueMoendas.includes(e.id))
        },
        async aceptar(){
            try {
                console.log('distinMonedas', this.distinMonedas);
                if(this.distinMonedas.some(e=> e.valor===0)){
                    this.notificacion('Mensaje', 'Campos en 0', 'warning')
                    return false
                }
                this.loading = true
                const payload ={
                    array: this.distinMonedas.map(e =>{
                        return {id_moneda: e.id,  valor: e.valor}
                    }),
                    id_producto: this.id_producto
                }
                const {data} = await CedisProductos.updateTodosCedis(payload)
                this.loading = false
                this.notificacion('Mensaje', 'Datos actualizados', 'success')
                this.$emit('actualizar')
                this.$refs.modalCambiarTodosCedis.toggle();

            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
    }
}
</script>
